Overlay.addModifier('flyout');

;(app => {
    app.plugins.delegate(document, 'click', '.js-open-basket', (e) => {
        e.preventDefault();
        let url = e.target.closest('.js-open-basket').getAttribute('href');
        app.flyout.load(url);
    });

    app.flyout = {
        load: (url, config = {}, add = false) => {
            window.Overlay.load(url, Object.assign({
                wait_for_scripts: true,
                modifiers: ['sidebar', 'flyout'],
                callback: (e) => {
                    app.loadBasket(add);
                },
                scrollable_element: overlay => {
                    const scrollable = overlay.content.querySelector('.basket__content');
                    if (scrollable) {
                        return scrollable;
                    }

                    return overlay.content;
                }
            }, config));
        }
    }

})(window.AndrewMartin = window.AndrewMartin || {});
